.ReportPledgePage {
  max-width: 411px;
  margin: 0 auto;
}

ul {
  text-align: left;
  color: #303a84;
  font-size: 20px;
  line-height: 1.18;
}

ul li {
  margin-bottom: 1em;
}
