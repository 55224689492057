@media only screen and (min-width: 769px) {
  .DistrictMaps_Container {
    border-left: 1px solid #888888;
    border-right: 1px solid #888888;
  }
}

@media only screen and (max-width: 768px) {
  .DistrictMaps_Container {
    min-width: 75vw;
    border-bottom: 1px solid #888888;
    order: 0;
  }
}

.DistrictMaps_Container {
  position: relative;
  flex: 3 0;
  z-index: 1;

  .react-transform-component,
  .react-transform-element {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  #flo-district-map {
    height: 100%;
    width: 100%;
  }
  .district {
    z-index: 1;
    cursor: pointer;

    &.no-reports:hover,
    &.no-reports:focus {
      fill: url(#diagonalHatchDark);
    }

    &:hover,
    &:focus {
      stroke-width: 2px !important;
    }
  }
}
