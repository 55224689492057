.MapButtons_Container {
  position: absolute;
  top: 5%;
  right: 40px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  background: #bce0fd79;
  border: 2px solid #303a84a2;
  padding: 6px 4px;
  border-radius: 8px;
  width: 30px;
  z-index: 2;
}

.Map_Button {
  border-radius: 8px;
  border: 2px solid #303a84cc;
  color: #303a84;
  background: rgba(219, 219, 219, 0.5);
  margin: 2px 0;
  width: 30px;
  height: 30px;
  cursor: pointer;

  &:hover,
  &:focus {
    background: #cecaca;
  }
}

.Map_Legend_Container {
  position: absolute;
  right: 40px;
  top: 75%;
  display: flex;
  flex-direction: row;
  background: #bce0fd79;
  border: 2px solid #303a84a2;
  padding: 6px 4px;
  border-radius: 8px;
  z-index: 2;
}

.Map_Legend_Col {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  align-items: center;
  margin: 0 0.1rem;
}

.Map_Legend_Color {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #303a84a2;
  margin: auto 0.5rem;
}

.Map_Legend_Label {
  margin: auto 0;
  text-align: center;
  color: #303a84;
  font-weight: bold;
  font-size: 0.6rem;
}

.color-nodata img {
  width: 100%;
  height: 100%;
}
.color-0 {
  background-color: rgb(255, 255, 255);
}
.color-s1 {
  background-color: #f8e9bf;
}
.color-s2 {
  background-color: #dba487;
}
.color-s3 {
  background-color: #ba605d;
}
.color-s4 {
  background-color: #94003a;
}

.color-t1 {
  background-color: #f2e1f5;
}
.color-t2 {
  background-color: #c990d6;
}
.color-t3 {
  background-color: #9445ab;
}
.color-t4 {
  background-color: #500b65;
}

@media only screen and (max-width: 768px) {
  // .Mapv2Overlay_Container {
  //   position: relative;
  //   display: flex;
  //   flex-direction: row;
  //   right: 0;
  //   top: -2.2rem;
  //   height: auto;
  // }

  .MapButtons_Container {
    position: relative;
    flex-direction: row;
    width: auto;
    top: -0.2rem;
    padding: 0;
    border: none;
    background-color: transparent;
    margin-right: auto;
    margin-left: 0.5rem;
  }

  .Map_Legend_Container {
    position: relative;
    top: 0%;
    padding: 0;
    border: none;
    background-color: transparent;
    margin-right: 0.5rem;
  }

  .Map_Legend_Color {
    width: 1rem;
    height: 1rem;
  }

  .Map_Legend_Label {
    font-size: 0.5rem;
  }

  .Map_Button {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
}
