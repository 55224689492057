.ThankYouPage {
  max-width: 411px;
  margin: 0 auto;
  color: #303a84;
  flex: 1;
}

.ThankYouPage_Content {
  max-width: 411px;
  margin: 0 auto;
  background-color: #303a84;
  color: white;
  flex: 1;
  padding: 2em;
  text-align: left;
}

.ThankYouPage_Content p {
  font-size: 1.25rem;
  line-height: 1.18;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.socials {
  margin-bottom: 1em;
  text-align: center;
}

.socials a {
  color: white;
  font-size: 3em;
  margin: 0.25rem;
  padding: 1rem;
  text-align: center;
}

.CodeForDayton {
  padding: 1em 2em;
  text-align: left;
  font-size: 1.15rem;
  line-height: 1.18;
}
