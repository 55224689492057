.MapHead_Container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}

.MapHead_Header {
  text-align: left;
  padding-left: 1rem;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  text-transform: uppercase;
}

/* for description next to very top header "SHORAGES" or "TESTING */
.MapHead_Header_Description1 {
  padding-left: 1rem;
  margin-block-start: 0em;
  margin-block-end: 0.5em;
  text-align: left;
}

.DateRange_Container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #888888;
  border-bottom: 1px solid #888888;
}

@media only screen and (max-width: 480px) {
  .DateRange_Container {
    flex-direction: column;
  }

  .DateRange_Start,
  .DateRange_End {
    width: auto !important;
    border: none !important;
    margin: auto;
    padding: 0.5rem 0;
  }

  .DateRange_Date {
    font-size: 1rem;
  }
}

.DateRange_Start,
.DateRange_End {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  min-width: 5.5em;
  width: 10em;
}

.DateRange_Start {
  margin-right: auto;
  border-right: 1px solid #888888;
}

.DateRange_End {
  margin-left: auto;
  border-left: 1px solid #888888;
}

.DateRange_Label {
  font-size: 14px;
}

.DateRange_Date {
  font-size: 18px;
  font-weight: bold;
}

.DateSlider_Container {
  width: 100%;
}

input[type='range'] {
  -webkit-appearance: none;
  height: 0.5rem;
  width: 90%;
  cursor: pointer;
  background: #303a84;
  border: 1px solid #22295e;
  border-radius: 8px;

  &:focus,
  &:hover {
    outline: none;
    background: #4452bb;
  }
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 2.5rem;
  width: 1rem;
  border-radius: 2px;
  background: #303a84;
  cursor: pointer;
  margin-top: 0px;
}
