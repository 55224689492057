.ExploreData_Container {
  display: flex;
  flex-direction: column;
  margin: 0rem 2rem 2rem;
  min-height: 16em;
}

.ExploreData_Text {
  text-align: left;
  padding: 1rem 0;
  text-transform: uppercase;
}

.ExploreData_Blurb {
  text-align: left;
}

.ExploreData_Buttons_Container {
  margin-right: auto;
}

.ExploreData_Buttons {
  color: #303a84;
  background-color: white;
  border-color: #303a84;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  padding: 0.25rem 0.75rem;
  border-radius: 0.75rem;
  margin-right: 1.5rem;
  cursor: pointer;
}

.ExploreData_Buttons.selectedShortages {
  background-color: #94003a;
  color: white;
  border-color: white;
}

.ExploreData_Buttons.selectedTesting {
  background-color: #500b65;
  color: white;
  border-color: white;
}

.ExploreData_Download_Link {
  display: block;
  text-decoration: none;
  color: white;
  background-color: #f9552e;
  border-color: #303a84;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  padding: 0.5rem 0.75rem;
  border-radius: 0.75rem;
  cursor: pointer;
  margin-top: 1.8rem;
  width: 9em;
}

@media (hover: hover) {
  .ExploreData_Buttons:hover {
    background-color: #d6edff;
  }

  .ExploreData_Download_Link:hover {
    background-color: #f9572ed8;
  }
  .ExploreData_Buttons.selectedShortages:hover {
    background-color: #94003a;
    color: white;
    border-color: white;
  }

  .ExploreData_Buttons.selectedTesting:hover {
    background-color: #500b65;
    color: white;
    border-color: white;
  }
}
