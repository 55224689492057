.DownloadPage {
  max-width: 700px;
  margin: 0 auto;
  color: #303a84;
  flex: 1;
}

.DownloadPage_Content {
  max-width: 700px;
  margin: 0 auto;
  background-color: #303a84;
  color: white;
  flex: 1;
  padding: 2em;
  text-align: left;
}

.DownloadPage_Content p {
  font-size: 1.1rem;
  line-height: 1.18;
}

/* .buttons class in ThankYouPage.css */

.Csv_Hidden {
  visibility: hidden;
}

#Download_Button {
  text-transform: uppercase;
  margin-bottom: 1em;
}

#Download_Button_Text {
  margin: auto;
  width: 10.5em;
  font-weight: bold;
}

.DownloadPage_Content_Bottom {
  display: flex;
  flex-direction: row;
  padding: 0.5em;
  text-align: left;
  font-size: 1rem;
  line-height: 1.18;
}

.DownloadPage_Content_Bottom div {
  width: 50%;
  padding: 0.5em;
}

.DownloadPage_Content_Bottom div:nth-of-type(2) {
  text-align: center;
}

.DownloadPage_Content_Bottom div:nth-of-type(2) p:nth-of-type(1) {
  font-size: 1.25rem;
  margin-block-start: 0.5rem;
  word-break: break-all;
}

.DownloadPage_Content_Bottom div:nth-of-type(2) p:nth-of-type(2) {
  text-align: left;
}

.DownloadPage_Content_Bottom i {
  color: #303a84;
  font-size: 3em;
}
