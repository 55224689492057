html,
body,
#root,
.App > div {
  height: 100%;
}

.App {
  text-align: center;
  background-color: #f3f7f8;
  overflow-x: hidden;
}

.page {
  min-height: 100vh;
  font-family: "Roboto";
}

/* form CSS */
/* TODO: MOVE THIS TO THE FORM COMPONENT PROBABLY */
.form_container {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: left;
  padding: 2em;
}

.form_error_message {
  color: #ff6565;
  height: 21px;
}

.form_error {
  border: 1px solid #ff6565 !important;
}

.shared_header {
  margin-block-start: 0;
  margin-block-end: 0;
  color: #303a84;
}

.MuiInputBase-root {
  color: #2699fb !important;
}

.UtilButton {
  background-color: #f9552e;
  width: 140px;
  margin: 1em auto 2em;
  padding: 6px 20px;
  font-weight: bold;
  color: white;
  line-height: 1.25;
  text-decoration: none;
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  text-align: center;
  border: transparent;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.UtilLink {
  display: block;
  background-color: #f9552e;
  width: 120px;
  margin: 1em auto 2em;
  padding: 6px 10px;
  font-weight: bold;
  color: white;
  line-height: 1.25;
  text-decoration: none;
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  text-align: center;
  border: transparent;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  cursor: pointer;
}

@media (hover: hover) {
  .UtilButton:hover {
    background-color: #f9572ed8;
  }
  .UtilLink:hover {
    background-color: #f9572ed8;
  }
}
