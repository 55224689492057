.HomePage {
  max-width: 700px;
  margin: 0 auto;
}

.content {
  padding: 1em;
  margin: auto;
}

.content p {
  text-align: left;
  color: #303a84;
  font-size: 1.25rem;
  line-height: 1.18;
}

#HomePage_takeaction {
  font-size: 1.35rem;
}

.Hero {
  display: flex;
  flex-direction: row-reverse;
  max-width: 411px;
  height: 350px;
  margin: auto;
  background: url("/images/nurse.png") center left no-repeat;
  background-size: contain;
  background-color: #303a84;
}

.HeroColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2em;
}

.HeroButton {
  background-color: #57cfed;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.15rem;
  line-height: 1.1;
  font-weight: bold;
  padding: 12px 10px;
  margin: 0.5em;
  width: 110px;
}

.HeroButton_Scroll {
  background: #57cfed;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.15rem;
  line-height: 1.1;
  font-weight: bold;
  padding: 12px 12px;
  margin: 0.5em;
  width: 110px;

  display: inline-block;
  position: relative;
}

.HeroButton_Scroll:before {
  border-top: 24px solid #57cfed;
  border-left: 67px solid transparent;
  border-right: 67px solid transparent;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  bottom: -24px;
  width: 0;
}

@media (hover: hover) {
  .HeroButton:hover,
  .HeroButton_Scroll:hover {
    background-color: #57cfede0;
  }

  .HeroButton_Scroll:hover:before {
    border-top: 24px solid #57cfede0;
    border-left: 67px solid transparent;
    border-right: 67px solid transparent;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    bottom: -24px;
    width: 0;
  }
}
